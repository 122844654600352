

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../../../config";
import { authHeader } from "../../../services/auth";
import commanModal from "../CommonModals.vue";
import { SalaryHead } from "../../../models/sub-domain/salary-head.model";

@Component({
  components: {
    commanModal
  }
})
export default class MySalaryHead extends Vue {
  public salaryHeadData = new SalaryHead();
  public salaryHeadList = [];
  public action = "Add";

  public async toAddSalaryHead() {
    this.$modal.show("salaryHead");
    this.action = "Add";
    this.salaryHeadData = new SalaryHead();
  }

  public async closeModal() {
    this.$modal.hide("salaryHead");
    this.fetchSalaryHeadList();
  }

  public editSalaryHead(salaryHead) {
    this.salaryHeadData = salaryHead;
    this.action = "Update";
    this.$modal.show("salaryHead");
  }

  public async saveSalaryHeadData() {
    try {
     
      let response = await Axios.post(
        BASE_API_URL + "payroll/saveSalaryHeadData",
        { ...this.salaryHeadData, action: this.action },
        {
          headers: authHeader()
        }
      );
      if (response.data.status == 300) {
       
        this.$snotify.warning("salary head already exist");
      
        return;
      } else if (response.status == 200)
        this.$snotify.success(response.data.message);
      this.$modal.hide("salaryHead");
      this.fetchSalaryHeadList();

    } catch (error) {
      console.log(error);
    }
  }

  public async fetchSalaryHeadList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "payroll/fetchSalaryHeadList",
        {
          headers: authHeader()
        }
      );
      this.salaryHeadList = response.data ? response.data : [];
    } catch (error) {
      console.log(error);
    }
  }

  public async deleteSalaryHead(salaryHead) {
    try {
      await this.$refs.ref["confirmationBox"]({
        msg: `Are you sure you want to delete this salary head?`,
        data: { salaryHead: salaryHead },
        loaderId: 4,
        apiPath: "payroll/deleteSalaryHead"
      });
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    await this.fetchSalaryHeadList();
  }
}
